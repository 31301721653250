<template>
  <v-layout>
    <AppointmentsAppBar
      :datasetIsSelected="selected"
      :searchTableIsActive="filterActive"
      @initHeaders="initialize"
      @filterChanged="setFilter"
      @resetFilters="resetFilter"
      @deleteSelectedDatasets="openDeleteDatasets"
      @createPDF="downloadPDF"
    />

    <v-data-table
      v-model="selected"
      calculate-widths
      class="pl-1"
      height="calc(100vh - 124px - 69px)"
      fixed-header
      hide-default-footer
      :items="filteredByRole"
      item-key="Terminanfrage_ID"
      :items-per-page="100"
      :item-class="rowColor"
      :headers="datasetHeaders"
      :page.sync="page"
      :search="filter.search"
      @page-count="pageCount = $event"
      @dblclick:row="showDataset"
      :single-select="false"
      :show-select="checkCurrentUser"
      mobile-breakpoint="300"
      style="width: 100%;"
    >
      <template #[`item.Anfragedatum`]="{ item }">
        <span v-if="item.Anfragedatum">{{
          new Date(item.Anfragedatum).toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }) + " Uhr"
        }}</span>
      </template>

      <template #[`header.Anfrage`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchRequest" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Anfrage
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchRequest" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Anfrage
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchRequest" dense clearable outlined hide-details autofocus placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>
      <template #[`item.Anfrage`]="{ item }">
        <span class="font-weight-bold">{{ item.Anfrage }}</span>
      </template>

      <template #[`header.Kundenname`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchClientname" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Kundenname
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchClientname" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Kundenname
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchClientname" dense clearable outlined hide-details autofocus placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>
      <template #[`item.Kundenname`]="{ item }">
        <span class="font-weight-bold">{{ item.Kundenname }}</span>
      </template>

      <template #[`header.Telefon`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchClientTelephone" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Telefon
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchClientTelephone" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Telefon
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchClientTelephone"
              dense
              clearable
              outlined
              hide-details
              autofocus
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Email`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchClientEmail" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Email
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchClientEmail" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Email
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchClientEmail"
              dense
              clearable
              outlined
              hide-details
              autofocus
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`item.Wunschdatum`]="{ item }">
        <span v-if="item.Wunschdatum">{{
          new Date(item.Wunschdatum).toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        }}</span>
        <span v-if="item.Wunschzeit" class="text-no-wrap">{{ ", " + item.Wunschzeit + " Uhr" }}</span>
      </template>

      <template #[`item.Alternativdatum`]="{ item }">
        <span v-if="item.Alternativdatum">{{
          new Date(item.Alternativdatum).toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        }}</span>
        <span v-if="item.Alternativzeit" class="text-no-wrap">{{ ", " + item.Alternativzeit + " Uhr" }}</span>
      </template>

      <template #[`header.Anlass`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchEvent" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Anlass
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchEvent" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Anlass
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchEvent" dense clearable outlined hide-details autofocus placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`item.Hochzeitstermin`]="{ item }">
        <span class="font-weight-bold" v-if="item.Hochzeitstermin">{{
          new Date(item.Hochzeitstermin).toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        }}</span>
      </template>

      <template #[`header.Rolle`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchRole" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Rolle
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchRole" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Rolle
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchRole" dense clearable outlined hide-details autofocus placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #no-data>
        <v-row dense align="center" justify="center" style="height: calc(100vh - 242px);">
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
          <v-col cols="8">
            <span>keine Daten gefunden - bitte Filter anpassen oder neue Daten erstellen</span>
          </v-col>
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
        </v-row>
      </template>
      <template #no-results>
        <v-row dense align="center" justify="center" style="height: calc(100vh - 242px);">
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
          <v-col cols="8">
            <span>keine Daten gefunden - bitte Filter anpassen oder neue Daten erstellen</span>
          </v-col>
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-footer app inset height="69" elevation="1">
      <v-col cols="12" class="pa-0">
        <v-row dense align="center" justify="start" class="px-1">
          <v-spacer></v-spacer>
          <v-col cols="8">
            <v-pagination color="grey darken-2" v-model="page" :length="pageCount"></v-pagination>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-footer>

    <DeleteDatasetsDialog ref="DeleteDatasets" @confirmDeleteDatasets="deleteDatasets" @abortDeleteDatasets="abortDeleteDatasets" />
  </v-layout>
</template>

<script>
import jsPDF from "jspdf";
import AppointmentsAppBar from "../../../components/appbars/AppointmentsAppBar.vue";
import DeleteDatasetsDialog from "../../../components/dialogs/DeleteDatasets.vue";

export default {
  components: {
    AppointmentsAppBar,
    DeleteDatasetsDialog,
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.initialize();
  },

  data: () => ({
    filter: {
      year: {},
      search: "",
      placeholder: null,
      category: 0,
    },
    tableSearch: {
      searchRequest: null,
      searchClientname: null,
      searchClientTelephone: null,
      searchClientEmail: null,
      searchEvent: null,
      searchRole: null,
    },
    datasets: [],
    datasetHeaders: [],
    ausgeblendeteSpalten: [],
    hiddenColumns: [],
    page: 1,
    pageCount: 0,
    selected: [],
    DeleteDatasets: false,
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "andre") {
        return true;
      } else return false;
    },

    filteredByPlaceholder() {
      if (this.datasets && !this.filter.placeholder) {
        return this.datasets.filter((item) => item.ist_offen === 1);
      } else if (this.datasets && this.filter.placeholder) {
        return this.datasets;
      }
    },

    filteredByCategory() {
      if (this.filteredByPlaceholder && this.filter.category >= 1) {
        return this.filteredByPlaceholder.filter((item) => item.Terminanfragekategorie_ID === this.filter.category);
      } else if (this.filteredByPlaceholder && this.filter.category === 0) {
        return this.filteredByPlaceholder;
      }
    },

    filteredByYear() {
      if (this.filteredByCategory && this.filter.year.value) {
        return this.filteredByCategory.filter((item) => new Date(item.Hochzeitstermin).getFullYear() === this.filter.year.value);
      } else return this.filteredByCategory;
    },

    filteredByShort() {
      if (this.tableSearch.searchRequestDate) {
        return this.filteredByYear.filter((item) => item.Abkürzung != null && item.Abkürzung.includes(this.tableSearch.searchRequestDate));
      } else return this.filteredByYear;
    },

    filteredByRequest() {
      if (this.tableSearch.searchRequest) {
        return this.filteredByShort.filter((item) => item.Anfrage != null && item.Anfrage.includes(this.tableSearch.searchRequest));
      } else return this.filteredByShort;
    },

    filteredByClientname() {
      if (this.tableSearch.searchClientname) {
        return this.filteredByRequest.filter((item) => item.Kundenname != null && item.Kundenname.includes(this.tableSearch.searchClientname));
      } else return this.filteredByRequest;
    },

    filteredByClientTelephone() {
      if (this.tableSearch.searchClientTelephone) {
        return this.filteredByClientname.filter((item) => item.Telefon != null && item.Telefon.includes(this.tableSearch.searchClientTelephone));
      } else return this.filteredByClientname;
    },

    filteredByClientEmail() {
      if (this.tableSearch.searchClientEmail) {
        return this.filteredByClientTelephone.filter((item) => item.Email != null && item.Email.includes(this.tableSearch.searchClientEmail));
      } else return this.filteredByClientTelephone;
    },

    filteredByEvent() {
      if (this.tableSearch.searchEvent) {
        return this.filteredByClientEmail.filter((item) => item.Anlass != null && item.Anlass.includes(this.tableSearch.searchEvent));
      } else return this.filteredByClientEmail;
    },

    filteredByRole() {
      if (this.tableSearch.searchRole) {
        return this.filteredByEvent.filter((item) => item.Rolle != null && item.Rolle.includes(this.tableSearch.searchRole));
      } else return this.filteredByEvent;
    },

    filterActive() {
      if (
        this.tableSearch.searchRequest ||
        this.tableSearch.searchClientname ||
        this.tableSearch.searchClientTelephone ||
        this.tableSearch.searchClientEmail ||
        this.tableSearch.searchEvent ||
        this.tableSearch.searchRole
      ) {
        return true;
      } else return false;
    },
  },

  methods: {
    setFilter(newfilter) {
      this.filter = Object.assign({}, newfilter);
    },

    resetFilter() {
      this.tableSearch.searchRequest = null;
      this.tableSearch.searchClientname = null;
      this.tableSearch.searchClientTelephone = null;
      this.tableSearch.searchClientEmail = null;
      this.tableSearch.searchEvent = null;
      this.tableSearch.searchRole = null;
    },

    resetSelected() {
      this.selected = [];
    },

    showDataset(event, { item }) {
      this.$router.push({
        path: `/wws/${this.$route.meta.request}/dataset/show/${item.Terminanfrage_ID}`,
      });
    },

    async initialize() {
      const response = await fetch(`/api/${this.$route.meta.request}/tableheaders`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.hiddenColumns = this.ausgeblendeteSpalten.map((item) => item.Spalte);

      this.resetFilter();

      this.initializeContent();
    },

    async initializeContent() {
      const response = await fetch(`/api/${this.$route.meta.request}/datasets`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.datasetHeaders = Object.keys(this.datasets[0])
        .filter((it) => this.hiddenColumns.indexOf(it) === -1)
        .map((it, i) => {
          return {
            text: it,
            value: it,
            sortable: false,
            filterable: true,
            align: "center",
            divider: true,
            class: "text--primary text-uppercase",
          };
        });
    },

    rowColor(item) {
      if (item.ist_offen === 1) {
        return "offen";
      } else if (item.ist_offen === 0) {
        return "verworfen";
      } else return "";
    },

    //DIALOG ZUR BESTÄTIGUNG UM EINEN TERMIN ZU LÖSCHEN
    openDeleteDatasets() {
      this.$refs.DeleteDatasets.open();
    },

    //TERMIN LÖSCHEN WENN LÖSCHUNG BESTÄTIGT
    deleteDatasets() {
      const multipleDatasetDelete = this.selected;
      const amountDeleteDatasets = multipleDatasetDelete.length;
      const request = this.$route.meta.request;

      multipleDatasetDelete.forEach(async function(dataset) {
        const datasetToDelete = {};
        const datasetHistoryToDelete = {};

        if (Object.keys(datasetToDelete).length === 0) {
          Object.assign(datasetToDelete, {
            Artikel_ID: dataset.Artikel_ID,
          });

          await fetch(`/api/${request}/dataset/delete`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(datasetToDelete),
          });
        }

        if (Object.keys(datasetHistoryToDelete).length === 0) {
          Object.assign(datasetHistoryToDelete, {
            Artikel_ID: dataset.Artikel_ID,
          });

          await fetch(`/api/${request}/dataset/events/delete`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(datasetHistoryToDelete),
          });
        }
      });

      this.$root.actionSnackbar.show({
        amount: String(amountDeleteDatasets),
        dataset: "Artikel",
        action: "gelöscht",
      });
      this.DeleteDatasets = false;
      this.selected = [];
      this.initialize();
    },

    //LÖSCHEN ABBRECHEN
    abortDeleteDatasets() {
      this.DeleteDatasets = false;
    },

    downloadPDF() {
      var initialdata = this.filteredByProductline;

      initialdata.forEach(function(o) {
        Object.keys(o).forEach(function(key) {
          if (o[key] === null || o[key] === "") {
            o[key] = " ";
          }
        });
      });

      var data = initialdata;

      var initialheaders = this.datasetHeaders.map((item) => item.text);
      var headers = initialheaders;

      var dateandtime = new Date();
      var currentdate = dateandtime.toLocaleDateString("de-DE");

      if (headers.length > 4) {
        var doc = new jsPDF({
          orientation: "landscape",
          floatPrecision: "smart",
        });
        doc.table(1, 1, data, headers, {
          autoSize: true,
          fontSize: 10,
          padding: 2,
        });
        doc.save("Herstellerliste - Stand " + currentdate + ".pdf");
      } else if (headers.length <= 4) {
        var doc = new jsPDF({
          orientation: "portrait",
          floatPrecision: "smart",
        });
        doc.table(1, 1, data, headers, {
          autoSize: true,
          fontSize: 10,
          padding: 2,
        });
        doc.save("Herstellerliste - Stand " + currentdate + ".pdf");
      }

      this.initialize();
    },
  },
};
</script>

<style>
.offen {
  background: white !important;
}

.verworfen {
  background: #ef535075 !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  cursor: pointer;
  background: #ddddddc5 !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.verworfen:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #ef535054 !important;
}
</style>
